import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { secondsToTime, timeToSeconds } from '../../helpers';
import ParticipantFieldInputProps from '../ParticipantFieldInputProps';
import UI from '../../UI';
import useIntegerInput from '../../useIntegerInput';

const TimeInput = ({ value, onChange, onBlur, field, touched, errors, validating }: ParticipantFieldInputProps) => {
  const { t } = useTranslation('common');
  const [id] = useState(Math.round(Math.random() * 1000000));
  const integerInput = useIntegerInput();

  const totalSeconds = value.value !== null && typeof value.value !== 'undefined' ? parseInt(value.value, 10) : null;
  const { hours, minutes, seconds } = secondsToTime(totalSeconds || 0);

  const form = useForm({
    values: {
      hours: totalSeconds !== null ? hours : null,
      minutes: totalSeconds !== null ? minutes : null,
      seconds: totalSeconds !== null ? seconds : null,
    },
  });

  const isEmpty = form.values.hours === null && form.values.minutes === null && form.values.seconds === null;
  const placeholder = isEmpty ? null : '0';

  /**
   * Used so that we can trigger change events only when the value has changed.
   */
  const valueRef = useRef(value.value);

  useEffect(() => {
    let newValue: string | null = null;

    if (!isEmpty) {
      // Represent value in seconds
      const totalSeconds = timeToSeconds(form.values.hours || 0, form.values.minutes || 0, form.values.seconds || 0);
      newValue = `${totalSeconds}`;
    }

    if (newValue !== valueRef.current) {
      onChange({ value: newValue });
      valueRef.current = newValue;
    }
  }, [isEmpty, form.values.hours, form.values.minutes, form.values.seconds, onChange]);

  return (
    <UI.InputGroup sc={{ valid: !errors?.value, touched }}>
      <UI.InputLabel htmlFor={`Hours_${id}`}>
        {field.title}
        {' '}
        {field.required && <UI.RequiredMark />}
        {validating && (
          <>
            {' '}
            <UI.Icon>
              <UI.Loader sc={{ brand: 'gray.400' }} />
            </UI.Icon>
          </>
        )}
      </UI.InputLabel>
      {field.description && (
        <UI.InputDescription as="div">
          <UI.HTML html={field.description} />
        </UI.InputDescription>
      )}
      <UI.FormGrid sc={{ columns: '1fr 1fr 1fr', gutter: 0.5 }}>
        <UI.Div>
          <UI.InputWrapper>
            <UI.DebouncedInput
              {...form.text('hours', { inputHandler: integerInput() })}
              placeholder={placeholder}
              maxLength={10}
              inputMode="numeric"
              match={/[\d]/g}
              onBlur={() => onBlur?.()}
              id={`Hours_${id}`}
            />
            <UI.InputLabel sc={{ muted: true }} htmlFor={`Hours_${id}`}>
              {t('time.hours', { count: form.values.hours })}
            </UI.InputLabel>
          </UI.InputWrapper>
        </UI.Div>
        <UI.Div>
          <UI.InputWrapper>
            <UI.DebouncedInput
              {...form.text('minutes', { inputHandler: integerInput() })}
              placeholder={placeholder}
              maxLength={2}
              inputMode="numeric"
              match={/^([0-9]|0[0-9]|[1-5][0-9])$/g}
              onBlur={() => onBlur?.()}
              id={`Minutes_${id}`}
            />
            <UI.InputLabel sc={{ muted: true }} htmlFor={`Minutes_${id}`}>
              {t('time.minutes', { count: form.values.minutes })}
            </UI.InputLabel>
          </UI.InputWrapper>
        </UI.Div>
        <UI.Div>
          <UI.InputWrapper>
            <UI.DebouncedInput
              {...form.text('seconds', { inputHandler: integerInput() })}
              placeholder={placeholder}
              maxLength={2}
              inputMode="numeric"
              match={/^([0-9]|0[0-9]|[1-5][0-9])$/g}
              onBlur={() => onBlur?.()}
              id={`Seconds_${id}`}
            />
            <UI.InputLabel sc={{ muted: true }} htmlFor={`Seconds_${id}`}>
              {t('time.seconds', { count: form.values.seconds })}
            </UI.InputLabel>
          </UI.InputWrapper>
        </UI.Div>
      </UI.FormGrid>
      <UI.ErrorMessages attribute={field.title} errors={errors?.value} />
    </UI.InputGroup>
  );
};

export default TimeInput;
