import * as yup from 'yup';
import { ChangeEvent, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { InvoiceDetailsInput, Locale } from '__generated__/graphql';

import { ErrorBag, validateVatId, zipCodeRegex } from '../helpers';
import AddressInput from '../ParticipantAttributes/Attributes/AddressInput';
import PhoneInput from '../ParticipantAttributes/Attributes/PhoneInput';
import UI from '../UI';
import useDebouncedEffect from '../useDebouncedEffect';
import useLocale from '../useLocale';

// Examples from https://www.avalara.com/vatlive/en/eu-vat-rules/eu-vat-number-registration/eu-vat-number-formats.html
const vatIdExamples: { [key: string]: string } = {
  AT: 'ATU12345678',
  BE: 'BE1234567890',
  BG: 'BG123456789',
  CH: 'CHE-123.456.789 MWST',
  CY: 'CY12345678X',
  CZ: 'CZ12345678',
  DE: 'DE123456789',
  DK: 'DK12345678',
  EE: 'EE123456789',
  EL: 'EL123456789',
  ES: 'ESX1234567X',
  FI: 'FI12345678',
  FR: 'FRXX123456789',
  GB: 'GB123456789',
  HR: 'HR12345678901',
  HU: 'HU12345678',
  IE: 'IE1234567WA',
  IT: 'IT12345678901',
  LT: 'LT123456789',
  LU: 'LU12345678',
  LV: 'LV12345678901',
  MT: 'MT12345678',
  NL: 'NL123456789B01',
  NO: 'NO123456789MVA',
  PL: 'PL1234567890',
  PT: 'PT123456789',
  RO: 'RO1234567890',
  SE: 'SE123456789012',
  SI: 'SI12345678',
  SK: 'SK1234567890',
};

interface InvoiceDetailsFormProps {
  value: InvoiceDetailsInput;
  onChange: (value: InvoiceDetailsInput) => void;
  onBlur?: (key: keyof InvoiceDetailsInput) => void;
  touched?: any;
  requirePhone?: boolean;
  disableVatId?: boolean;
  errors?: ErrorBag;
}

const InvoiceDetailsForm = ({
  value, onChange, onBlur, touched, requirePhone = false, disableVatId = false, errors,
}: InvoiceDetailsFormProps) => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();

  const handleChange = (field: keyof InvoiceDetailsInput) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [field]: event.target.value,
      });
    }
  );

  const country = value.zip_code && value.country;
  const localeToCountry: { [key: string]: string; } = {
    [Locale.de]: 'DE',
    [Locale.es]: 'ES',
    [Locale.fr]: 'FR',
    [Locale.nl]: 'NL',
  };
  const vatIdExample = vatIdExamples[country] || vatIdExamples[localeToCountry[locale]] || vatIdExamples.NL;
  const isValidVatId = !value.vat_id || validateVatId(value.vat_id);
  const [showVatIdWarning, setShowVatIdWarning] = useState(!isValidVatId);

  useDebouncedEffect(() => {
    setShowVatIdWarning(!isValidVatId);
  }, 1000, [setShowVatIdWarning, isValidVatId]);

  return (
    <UI.FormGrid>
      <UI.InputGroup sc={{ valid: !errors?.company_name, touched: touched?.company_name }}>
        <UI.InputLabel htmlFor="InvoiceCompanyName">
          {t('invoice_details.company_name')}
          {' '}
          <UI.RequiredMark />
        </UI.InputLabel>
        <UI.DebouncedInput
          value={value.company_name}
          onChange={handleChange('company_name')}
          onBlur={() => onBlur?.('company_name')}
          id="InvoiceCompanyName"
        />
        <UI.ErrorMessages attribute={t('invoice_details.company_name')} errors={errors?.company_name} />
      </UI.InputGroup>
      <UI.InputGroup sc={{ valid: !errors?.is_charity, touched: touched?.is_charity }}>
        <UI.Checkbox
          checked={value.is_charity}
          onChange={() => onChange({ ...value, is_charity: !value.is_charity })}
        >
          {t('invoice_details.is_charity')}
        </UI.Checkbox>
        <UI.ErrorMessages attribute={t('invoice_details.is_charity')} errors={errors?.is_charity} />
      </UI.InputGroup>
      <UI.InputGroup sc={{ valid: !errors?.reference, touched: touched?.reference }}>
        <UI.InputLabel htmlFor="InvoiceReference">
          {t('invoice_details.reference')}
        </UI.InputLabel>
        <UI.DebouncedInput
          value={value.reference}
          onChange={handleChange('reference')}
          onBlur={() => onBlur?.('reference')}
          id="InvoiceReference"
        />
        <UI.ErrorMessages attribute={t('invoice_details.reference')} errors={errors?.reference} />
      </UI.InputGroup>

      <UI.InputGroup sc={{ valid: !errors?.vat_id, touched: touched?.vat_id }}>
        <UI.InputLabel htmlFor="InvoiceVatId">
          {t('invoice_details.vat_id')}
        </UI.InputLabel>
        <UI.InputDescription>
          {t('invoice_details.vat_id_description', { example: vatIdExample })}
        </UI.InputDescription>
        <UI.DebouncedInput
          value={value.vat_id}
          onChange={handleChange('vat_id')}
          onBlur={() => { setShowVatIdWarning(!isValidVatId); onBlur?.('vat_id'); }}
          id="InvoiceVatId"
          disabled={disableVatId}
        />
        <UI.ErrorMessages attribute={t('invoice_details.vat_id')} errors={errors?.vat_id} />
        {showVatIdWarning && (
          <UI.FadeIn sc={{ mt: 2 }}>
            <UI.Info>
              {t('invoice_details.invalid_vat_id')}
            </UI.Info>
          </UI.FadeIn>
        )}
      </UI.InputGroup>

      <AddressInput
        value={value}
        onChange={onChange}
        onBlur={(field: any) => onBlur?.(field)}
        touched={touched}
        errors={errors}
        idPrefix="Invoice"
      />
      <PhoneInput
        value={value}
        onChange={onChange}
        onBlur={(field: any) => onBlur?.(field)}
        touched={touched}
        errors={errors}
        required={requirePhone}
        idPrefix="Phone"
      />
    </UI.FormGrid>
  );
};

export const getInvoiceSchema = (requirePhone = false) => yup.object({
  company_name: yup.string().ensure().required(),
  reference: yup.string().ensure(),
  vat_id: yup.string().ensure(),
  street: yup.string().ensure().required(),
  house_number: yup.string().ensure().required().when(['country'], {
    is: (country: string) => country === 'NL',
    then: yup.string().ensure().test('invalid', (value) => (
      // Dutch house numbers should contain at least one digit
      !value || !!value.match(/\d/)
    )),
  }),
  zip_code: yup.string().ensure().required().when(['country'], {
    is: (country: string) => country === 'NL',
    then: yup.string().ensure().test('zip_code', (value) => (
      // Remove non-alphanumeric characters and check if it matches 1111AA
      !value || !!value.replace(/[^0-9a-z]/gi, '').match(zipCodeRegex.NL)
    )),
  }),
  city: yup.string().ensure().required(),
  country: yup.string().ensure().required(),
  phone: (requirePhone ? yup.string().ensure().required() : yup.string().ensure())
    .test('phone', '', (value) => !value || isValidPhoneNumber(value)),
});

export const emptyInvoiceDetails: InvoiceDetailsInput = {
  company_name: '',
  is_charity: false,
  reference: '',
  vat_id: null, // Optional field
  street: '',
  house_number: '',
  zip_code: '',
  extra_address_line: null, // Optional field
  city: '',
  country: '',
  phone: null, // Optional field
};

export default InvoiceDetailsForm;
