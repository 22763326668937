import { Check } from 'react-feather';
import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { ArrayResponsiveProp, ResponsiveProp, SimpleProp } from '../../theme/helpers';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

type HtmlCheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface CheckboxProps extends HtmlCheckboxProps, SC<CheckboxLabelProps & UIProps> {
  children?: ReactNode;
  type?: 'checkbox';
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
}

const Checkbox = ({ checked, disabled, sc, style, id, children, ...props }: CheckboxProps) => {
  const identifier = id || `checkbox-${Math.round(Math.random() * 1000000)}`;

  return (
    <CheckboxLabel sc={{ ...sc, checked, disabled }} style={style} htmlFor={identifier}>
      <input type="checkbox" checked={checked} disabled={disabled} id={identifier} {...props} />
      <span>{checked && <Check />}</span>
      {children}
    </CheckboxLabel>
  );
};

interface CheckboxLabelProps {
  box?: boolean;
  checked?: boolean;
  disabled?: boolean;
  checkboxPositionV?: 'top' | 'center';
  checkboxTop?: ResponsiveProp<string | number> | SimpleProp<string | number> | ArrayResponsiveProp<string | number>;
  checkboxLeft?: ResponsiveProp<string | number> | SimpleProp<string | number> | ArrayResponsiveProp<string | number>;
}

export const CheckboxLabel = styled.label<SC<CheckboxLabelProps>>`
  ${({ sc: { box, checked, disabled, checkboxPositionV = 'top', checkboxTop, checkboxLeft, ...props } = {}, theme }) => css`
    display: block;
    position: relative;
    padding: .1em 0 .1em 24px;
    cursor: ${disabled ? 'default' : 'pointer'};

    ${disabled && css`
      opacity: 0.5;
    `}

    input {
      position: absolute;
      left: 0;
      top: .1em;
      opacity: 0;

      & + span {
        position: absolute;
        left: ${checkboxLeft || 0};
        display: block;
        width: 13px;
        height: 13px;
        background: ${theme.colors.gray[200]};
        border-radius: 2px;
        color: ${theme.colors.secondary[500]};
        text-align: center;
        line-height: 14px;

        ${checkboxTop && theme.responsiveProp(checkboxTop, (value: number | string) => css`
          top: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
        ${!checkboxTop && css`
          top: 0.35em;
        `}

        ${checkboxLeft && theme.responsiveProp(checkboxLeft, (value: number | string) => css`
          left: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
        ${!checkboxLeft && css`
          left: 0;
        `}

        svg {
          width: 13px;
          height: 13px;
          stroke-width: 2.5;
        }
      }

      &:checked + span {
        background: ${theme.colors.secondary[500]};
        color: ${theme.colors.white};
      }

      &:focus-visible + span {
        outline: 2px solid ${theme.getSufficientContrast(theme.getColor('secondary.500'))};
        outline-offset: 1px;
      }
    }

    ${box && css`
      background: ${theme.colors.white};
      display: inline-block;
      border: 1px solid ${theme.colors.gray[250]};
      border-radius: ${theme.borderRadiuses.md}px;
      padding: 8px 10px 8px ${theme.gutter * 1.3}px;
      min-height: 40px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

      &:hover {
        border-color: ${theme.colors.gray[300]};
      }

      input + span {
        left: 10px;

        ${checkboxPositionV === 'top' && css`
          top: 12px;
        `};

        ${checkboxPositionV === 'center' && css`
          top: 50%;
          transform: translateY(-50%);
        `};

        ${checkboxTop && theme.responsiveProp(checkboxTop, (value: number) => css`
          top: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}

        ${checkboxLeft && theme.responsiveProp(checkboxLeft, (value: number) => css`
          left: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
      }

      ${checked && css`
        z-index: 1;
      `}
    `}

    ${theme.useMixins(props)}
  `}
`;

export default Checkbox;
