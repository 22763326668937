import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import useScroll from './useScroll';

interface UseFocusIndexOptions {
  initialIndex?: number;
  maxIndex?: number;
  active?: boolean;
  onSelect: (index: number) => void;
  onCancel?: (index: number) => void;
}

const useFocusIndex = ({
  initialIndex = 0, maxIndex, active = true, onSelect, onCancel,
}: UseFocusIndexOptions) => {
  const { scrollTo } = useScroll();
  const [focusIndex, setFocusIndex] = useState(initialIndex);
  const containerRef = useRef(undefined);

  const scrollToIndex = useCallback((index: number) => {
    const container = containerRef.current;

    if (container) {
      const { id } = container;

      if (id) {
        const item = document.getElementById(`${id}_${index}`);

        if (item) {
          const offsetTop = item.offsetTop - container.offsetTop;

          // Scroll down
          if (offsetTop + item.offsetHeight > container.scrollTop + container.offsetHeight) {
            scrollTo(`${id}_${index}`, {
              scrollContainer: id,
              duration: 300,
            });
          }

          // Scroll up
          if (offsetTop < container.scrollTop) {
            scrollTo(`${id}_${index}`, {
              scrollContainer: id,
              duration: 300,
            });
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (active) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 40) {
          // Down arrow
          event.preventDefault();
          const newIndex = focusIndex === maxIndex ? 0 : focusIndex + 1;
          setFocusIndex(newIndex);
          scrollToIndex(newIndex);
        } else if (event.keyCode === 38) {
          // Up arrow
          event.preventDefault();
          const newIndex = focusIndex === 0 ? maxIndex : focusIndex - 1;
          setFocusIndex(newIndex);
          scrollToIndex(newIndex);
        } else if (event.keyCode === 13) {
          // Enter key
          event.preventDefault();

          if (focusIndex > -1) {
            onSelect?.(focusIndex);
          }
        } else if (event.keyCode === 32) {
          // Space key
          const target = event.target as Element;

          if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {
            // If the user is typing in an input we don't want the space bar to have special behaviour
            event.preventDefault();

            if (focusIndex > -1) {
              onSelect?.(focusIndex);
            }
          }
        } else if (event.keyCode === 27) {
          // Escape key
          event.preventDefault();
          setFocusIndex(0);
          onCancel?.(focusIndex);
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }

    return undefined;
  }, [maxIndex, focusIndex, active, onCancel, onSelect, scrollToIndex]);

  return [
    focusIndex, setFocusIndex, containerRef, scrollToIndex,
  ] as [number, Dispatch<SetStateAction<number>>, any, (index: number) => void];
};

export default useFocusIndex;
