import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { ArrayResponsiveProp, ResponsiveProp, SimpleProp } from '../../theme/helpers';
import { Div } from './Generic';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

type HtmlRadioProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface RadioLabelProps {
  box?: boolean;
  checked?: boolean;
  disabled?: boolean;
  hideRadio?: boolean;
  radioPositionV?: 'top' | 'center';
  radioTop?: ResponsiveProp<string | number> | SimpleProp<string | number> | ArrayResponsiveProp<string | number>;
  radioLeft?: ResponsiveProp<string | number> | SimpleProp<string | number> | ArrayResponsiveProp<string | number>;
}

interface RadioProps extends HtmlRadioProps, SC<RadioLabelProps & UIProps> {
  children?: ReactNode;
  type?: 'radio';
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
}

const Radio = ({ checked, disabled, sc, style, id, children, ...props }: RadioProps) => {
  const identifier = id || `radio-${Math.round(Math.random() * 1000000)}`;

  return (
    <RadioLabel sc={{ ...sc, checked, disabled }} style={style} htmlFor={identifier}>
      <input type="radio" checked={checked} disabled={disabled} id={identifier} {...props} />
      {!sc?.hideRadio && <RadioBullet />}
      {children}
    </RadioLabel>
  );
};

const RadioBullet = styled.span``;

export const RadioLabel = styled.label<SC<RadioLabelProps>>`
  ${({
    sc: { box, checked, disabled, hideRadio, radioPositionV = 'top', radioTop, radioLeft, ...props } = {},
    theme,
  }) => css`
    display: block;
    position: relative;
    padding: .1em 0 .1em ${hideRadio ? 0 : '24px'};
    cursor: ${disabled ? 'default' : 'pointer'};

    ${disabled && css`
      opacity: 0.5;
    `}

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 1px;
      opacity: 0;

      & + ${RadioBullet} {
        position: absolute;
        display: inline-block;
        width: 13px;
        height: 13px;
        background: ${theme.colors.gray[200]};
        border: 4px solid ${theme.colors.gray[200]};
        border-radius: 100%;
        text-align: center;
        line-height: 14px;

        ${radioTop && theme.responsiveProp(radioTop, (value: number | string) => css`
          top: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
        ${!radioTop && css`
          top: 0.35em;
        `}

        ${radioLeft && theme.responsiveProp(radioLeft, (value: number | string) => css`
          left: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
        ${!radioLeft && css`
          left: 0;
        `}

        svg {
          width: 13px;
          height: 13px;
          stroke-width: 2.5;
        }
      }

      &:checked + ${RadioBullet} {
        background: ${theme.colors.white};
        border-color: ${theme.colors.secondary[500]};
        color: ${theme.colors.white};
      }

      &:focus-visible + ${RadioBullet} {
        outline: 2px solid ${theme.getSufficientContrast(theme.getColor('secondary.500'))};
        outline-offset: 1px;
      }
    }

    ${box && css`
      background: ${theme.colors.white};
      display: inline-block;
      border: 1px solid ${theme.colors.gray[250]};
      border-radius: ${theme.borderRadiuses.md}px;
      padding: 8px 10px;
      min-height: 40px;
      ${!hideRadio && css`
        padding-left: ${theme.gutter * 1.3}px;
      `}
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

      &:hover {
        border-color: ${theme.colors.gray[300]};
      }

      input + ${RadioBullet} {
        left: 10px;

        ${radioPositionV === 'top' && css`
          top: 12px;
        `};

        ${radioPositionV === 'center' && css`
          top: 50%;
          transform: translateY(-50%);
        `};

        ${radioTop && theme.responsiveProp(radioTop, (value: number) => css`
          top: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}

        ${radioLeft && theme.responsiveProp(radioLeft, (value: number) => css`
          left: ${value}${typeof value === 'number' ? 'px' : ''};
        `)}
      }

      ${checked && css`
        z-index: 1;
      `}

      ${disabled && css`
        opacity: 0.5;
      `}
    `}

    ${theme.useMixins(props)}
  `}
`;

interface RadioPillProps {
  vertical?: boolean;
}

export const RadioPill = styled(Div)<SC<RadioPillProps>>`
  ${({ sc: { vertical = false } = {}, theme }) => css`
    border-radius: ${theme.borderRadiuses.md}px;
    display: flex;

    ${RadioLabel} {
      margin-bottom: 0;
    }

    ${RadioLabel}:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    ${!vertical && css`
      ${RadioLabel} {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;

        input + ${RadioBullet} {
          position: relative;
          top: 0;
          left: 0;
          margin-right: ${theme.gutter / 3}px;
          margin-bottom: -1px;
        }
      }

      ${RadioLabel}:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      ${RadioLabel}:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      ${RadioLabel} + ${RadioLabel} {
        margin-left: -1px;
      }

    `}

    ${vertical && css`
      flex-wrap: wrap;

      ${RadioLabel} {
        width: 100%;
      }

      ${RadioLabel}:first-child:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      ${RadioLabel}:last-child:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      ${RadioLabel} + ${RadioLabel} {
        margin-top: -1px;
      }
    `}
  `}
`;

export default Radio;
