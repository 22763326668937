import { FlattenSimpleInterpolation, css } from 'styled-components';
import Color from 'color';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export function getColor(key: string, value = 500): string {
  const color = get(this.colors, key);

  if (isObject(color)) {
    return (color as { [key in string | number]: string })[value];
  }

  return color;
}

export function getLinkColor(background: string): string {
  if (background === 'transparent') {
    return this.colors.link;
  }

  return this.getBestContrast(
    desaturate(darkenColor(background, 10), 75),
    this.colors.white,
    this.colors.link,
  );
}

export function getTextColor(background: string): string {
  if (background === 'transparent') {
    return this.colors.body;
  }

  const bgColor = new Color(background);
  const lightColor = bgColor.mix(new Color('white'), 0.7);

  return this.getBestContrast(
    desaturate(darkenColor(background, 10), 75),
    lightColor.hex(),
    this.colors.body,
  );
}

export function getTitleColor(background: string): string {
  if (background === 'transparent') {
    return this.colors.body;
  }

  return this.getBestContrast(
    desaturate(darkenColor(background, 10), 75),
    this.colors.white,
    this.colors.gray[800],
  );
}

export function getSufficientContrast(color: string, background: string = 'white'): string {
  let foregroundColor = new Color(color);
  const backgroundColor = new Color(background);

  while (foregroundColor.contrast(backgroundColor) < 3) {
    foregroundColor = foregroundColor.darken(backgroundColor.isLight() ? 0.05 : -0.05);
  }

  return foregroundColor.hex();
}

export function getBestContrast(background: string, foreground1: string, foreground2: string): string {
  const backgroundColor = (new Color(background)).darken(0.225);
  const foregroundColor1 = new Color(foreground1);
  const foregroundColor2 = new Color(foreground2);

  if (backgroundColor.contrast(foregroundColor1) > backgroundColor.contrast(foregroundColor2)) {
    return foregroundColor1.hex();
  }

  return foregroundColor2.hex();
}

export function darkenColor(cssColor: string, percentage = 10): string {
  return Color(cssColor).darken(percentage / 100).hex();
}

export function lightenColor(cssColor: string, percentage = 10): string {
  return Color(cssColor).lighten(percentage / 100).hex();
}

export function mix(cssColor1: string, cssColor2: string, weight: number = 0.5): string {
  return Color(cssColor1).mix(Color(cssColor2), weight).hex();
}

export function saturate(cssColor: string, percentage = 10): string {
  return Color(cssColor).saturate(percentage / 100).hex();
}

export function desaturate(cssColor: string, percentage = 10): string {
  return Color(cssColor).desaturate(percentage / 100).hex();
}

export function transparentize(cssColor: string, ratio: number): string {
  return Color(cssColor).fade(ratio).toString();
}

type Device = 'sm' | 'md' | 'lg';

export type SimpleProp<T = any> = T;

export type ResponsiveProp<T = any> = {
  [key in Device]?: SimpleProp<T>;
};

export type ArrayResponsiveProp<T = any> = {
  [key: number]: SimpleProp<T>;
};

const isResponsiveProp = (
  value: ResponsiveProp | SimpleProp | ArrayResponsiveProp,
): value is ResponsiveProp => (
  isObject(value) && ('sm' in value || 'md' in value || 'lg' in value)
);

const isArrayResponsiveProp = (
  value: ResponsiveProp | SimpleProp | ArrayResponsiveProp,
): value is ArrayResponsiveProp => isArray(value);

export function responsiveProp(
  value: ResponsiveProp | SimpleProp,
  callback: (prop: SimpleProp) => FlattenSimpleInterpolation,
): FlattenSimpleInterpolation {
  const devices = this.devices as {
    [key in Device]: string;
  };

  if (isResponsiveProp(value)) {
    return css`${Object.keys(devices).map((device: Device) => (
      typeof value[device] !== 'undefined' ? `
        @media only screen and ${devices[device]} {
          ${callback(value[device]).join('')}
        }
      ` : ''
    )).join('')}`;
  }

  if (isArrayResponsiveProp(value)) {
    return css`${Object.keys(devices).map((device: Device, index: number) => (
      typeof value[index] !== 'undefined' ? `
        @media only screen and ${devices[device]} {
          ${callback(value[index]).join('')}
        }
      ` : ''
    )).join('')}`;
  }

  return callback(value);
}

export function evaluateWidth(width: number | string) {
  if (typeof width === 'number') {
    return `${width * 100}%`;
  }

  return width;
}

export default undefined;
