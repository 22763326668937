import styled, { css } from 'styled-components';

import { Div } from './Generic';
import SC from './SC';

interface FadeInProps {
  when?: boolean;
  delay?: boolean;
}

const FadeIn = styled(Div)<SC<FadeInProps>>`
  ${({ sc: { when = true, delay = true } = {} }) => {
    const name = `fade-in-${delay ? 'delayed' : ''}`;

    return css`
      ${when && css`
        animation: ${name} 0.2s ease-in;
      `}

      @keyframes ${name} {
        0% { opacity: 0; }
        ${delay && css`40% { opacity: 0; }`}
        100% { opacity: 1; }
      }`;
  }}
`;

export default FadeIn;
