import styled, { css } from 'styled-components';

import { AButton, Button, LinkButton, NavButton } from './Button';
import { Caret } from './Text';
import { Div, Span } from './Generic';
import SC from './SC';

interface DropdownProps {
  open?: boolean;
  alignH?: 'left' | 'center' | 'right';
  alignV?: 'top' | 'center' | 'bottom';
}

const Dropdown = styled(Div)<SC<DropdownProps>>`
  ${({ sc: { open, alignH, alignV } = {}, theme }) => {
    const translateX = alignH === 'center' ? '-50%' : 0;
    const translateY = alignV === 'top' ? '-100%' : (alignV === 'center' ? '-50%' : (alignV === 'bottom' ? '100%' : 0));

    return css`
      position: absolute;
      visibility: hidden;
      transition: all 0.1s ease-in;
      opacity: 0;
      bottom: 0;
      box-shadow: ${theme.shadows.sm}, ${theme.shadows.lg}, 0 0 0 3px rgba(0, 0, 0, .01);
      transform: translateY(100%);
      overflow: hidden;
      background: ${theme.colors.white};
      border-radius: ${theme.borderRadiuses.md}px;
      z-index: 400;
      min-width: 100%;

      ${(alignH || alignV) && css`
        transform: translate(${translateX}, ${translateY});
      `}

      ${alignH === 'left' && css`
        left: 0;
      `}

      ${alignH === 'center' && css`
        left: 50%;
      `}

      ${alignH === 'right' && css`
        right: 0;
      `}

      ${alignV === 'top' && css`
        bottom: initial;
        top: 0;
      `}

      ${alignV === 'center' && css`
        bottom: initial;
        top: 50%;
      `}

      ${alignV === 'bottom' && css`
        top: initial;
        bottom: 0;
      `}

      ${open && css`
        opacity: 1;
        visibility: visible;
      `}

      ${Button}, ${NavButton}, ${LinkButton}, ${AButton} {
        border: none;
        background: none;
        border-radius: 0;
        color: ${theme.colors.gray[700]};
        display: block;
        width: 100%;
        padding: ${theme.gutter / 3}px ${theme.gutter / 2}px;
        white-space: nowrap;
        box-shadow: none;
        text-align: left;

        &:not(:first-child) {
          border-top: 1px solid ${theme.colors.gray[200]};
        }

        &:hover {
          background: ${theme.colors.gray[200]};
        }

        &:disabled {
          opacity: 1 !important;
          color: ${theme.colors.gray[400]} !important;
        }
      }
    `;
  }}
`;

export const DropdownToggle = styled(Button)``;

interface DropdownOptionsContainerProps {
  isDisabled?: boolean;
}

export const DropdownOptionsContainer = styled(Span)<SC<DropdownOptionsContainerProps>>`
  ${({ theme, sc: { isDisabled } = {} }) => css`
    position: relative;
    display: inline-block;

    ${isDisabled && css`
      pointer-events: none;
      opacity: 0.2;
    `}

    > ${DropdownToggle} {
      background: transparent;
      border: none;
      color: ${theme.colors.gray[500]};
      font-size: 0.75rem;
      line-height: 1;
      padding: ${theme.gutter / 3}px ${theme.gutter / 4}px;

      &:hover {
        color: ${theme.colors.gray[800]};
      }

      ${Caret} {
        margin-left: 0;
      }
    }
  `}
`;

export default Dropdown;
