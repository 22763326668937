import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import {
  GetCheckoutSummaryQuery as GetCheckoutSummary,
  GetCheckoutSummaryQueryVariables as GetCheckoutSummaryVariables,
  RegistrationsInput,
} from '__generated__/graphql';

import GetCheckoutSummaryQuery from './GetCheckoutSummaryQuery';
import useMemoizedValue from './useMemoizedValue';
import useQuery from '../api/useQuery';

interface UseCheckoutSummaryProps {
  event: { id: string; };
  registrations: RegistrationsInput;
  invitationCode?: string | null;
  coupon?: string | null;
  couponValidation?: boolean;
  vatId?: string | null;
  queueToken?: string | null;
  onChange?: (data: GetCheckoutSummary) => void;
}

const useCheckoutSummary = ({
  event, registrations, invitationCode = null, coupon = null, couponValidation, vatId = null, queueToken = null,
  onChange,
}: UseCheckoutSummaryProps) => {
  const itemsCount = (registrations.create?.length || 0)
    + (registrations.update?.length || 0)
    + (registrations.business?.length || 0)
    + (registrations.stand_alone_upgrades?.length || 0);

  /**
   * Memoize the variables so that we can debounce the server request.
   */
  const currentVariables: GetCheckoutSummaryVariables = useMemoizedValue({
    input: {
      event: { id: event.id },
      // Filter out some fields because we want to prevent server-side validation on them
      registrations: convertRegistrationsInput(registrations),
      invitation_code: invitationCode,
      coupon,
      coupon_validation: couponValidation,
      vat_id: vatId,
      queue_token: queueToken,
    },
    event_id: event.id,
    // Only include the event (with current ticket and product capacities/availaibilities)
    // if any tickets or products are selected
    include_event: itemsCount > 0,
    invitation_code: invitationCode,
  });

  const [variables, setVariables] = useState(currentVariables);

  /**
   * After the variables have changed, wait 250 ms before refetching the query.
   */
  useEffect(() => {
    const timer = window.setTimeout(() => setVariables(currentVariables), 250);

    return () => window.clearTimeout(timer);
  }, [currentVariables]);

  const [data, setData] = useState<GetCheckoutSummary>();

  const { loading: fetching, refetch } = useQuery(
    GetCheckoutSummaryQuery,
    {
      variables,
      onCompleted: (data) => {
        // Store the data in state instead of using the result of the query directly, because we
        // want to prevent the query from falling back to outdated previousData. Falling back to
        // outdated previousData might cause an outdated checkout timer to be shown briefly.
        // For example: without this mechanism, if the user selects one ticket with 15 minutes
        // left, then selects another 1 minute later (with 14 minutes left), then deselects that
        // one, the timer will briefly show '15 mintues left' until the new response has been
        // fetched.
        // Note: this behavior is independent of the fetchPolicy.
        setData(data);
        onChange?.(data);
      },
      fetchPolicy: 'network-only',
    },
  );

  /**
   * The UI should show a loading animation as soon as the variables change, until the query is done refetching.
   */
  const loading = !isEqual(currentVariables, variables) || fetching;

  // As an optimization, return the default summary when no tickets or products are selected
  const checkoutSummary = data?.checkoutSummary || defaultSummary;

  return { checkoutSummary, loading, data, refetch };
};

/** An empty checkout summary, can be used when no tickets are selected. */
export const defaultSummary: GetCheckoutSummary['checkoutSummary'] = {
  amount: 0,
  vat_amount: 0,
  passed_on_fee: 0,
  passed_on_fee_vat_amount: 0,
  down_payment_amount: 0,
  down_payment_passed_on_fee: 0,
  down_payment_passed_on_fee_vat_amount: 0,
  coupon_status: null,
  tickets: [],
  products: [],
  coupon_code: null,
  queue: null,
  time_left: 300,
  total_time: 300,
  __typename: 'PurchasesSummary',
};

export const convertRegistrationsInput = (registrations: RegistrationsInput) => ({
  create: registrations.create?.map((registration) => ({
    ticket: registration.ticket,
    time_slot: registration.time_slot,
    purchase: registration.purchase,
    upgrades: registration.upgrades.map((upgrade) => ({
      product: upgrade.product,
      product_variant: upgrade.product_variant,
      purchase: upgrade.purchase,
    })),
  })) || [],
  update: registrations.update?.map((registration) => ({
    id: registration.id,
    upgrades: registration.upgrades.map((upgrade) => ({
      product: upgrade.product,
      product_variant: upgrade.product_variant,
      purchase: upgrade.purchase,
    })),
  })) || [],
  business: registrations.business || [],
  stand_alone_upgrades: registrations.stand_alone_upgrades?.map((upgrade) => ({
    product: upgrade.product,
    product_variant: upgrade.product_variant,
    purchase: upgrade.purchase,
  })) || [],
});

export default useCheckoutSummary;
