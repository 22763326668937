import { gql } from '__generated__';

export default gql(`
  query GetCheckoutSummary($input: CheckoutSummaryInput!, $event_id: ID!, $include_event: Boolean!, $invitation_code: String) {
    checkoutSummary(input: $input) {
      amount
      vat_amount
      passed_on_fee
      passed_on_fee_vat_amount
      down_payment_amount
      down_payment_passed_on_fee
      down_payment_passed_on_fee_vat_amount
      coupon_status
      queue {
        id
        starting_at
        token_url
      }
      time_left
      total_time
      tickets {
        quantity
        purchase {
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          ticket {
            id
            title
          }
          time_slot {
            id
            start_date
            start_time
            multi_date
            title
          }
        }
      }
      products {
        quantity
        purchase {
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          product {
            id
            title
            is_ticket_fee
          }
          product_variant {
            id
            title
          }
        }
      }
      coupon_code {
        id
        value
        coupon {
          id
          type
          value
        }
      }
    }
    # Should only include the event if any tickets or products are selected
    event(id: $event_id) @include(if: $include_event) {
      id
      ticket_categories(invitation_code: $invitation_code) {
        id
        current_max_per_order
        tickets_for_sale {
          id
          current_max_per_order
          is_sold_out
          upcoming_time_slots {
            id
            current_max_per_order
            is_sold_out
          }
          promotions_for_sale {
            id
            current_max_per_order
            is_sold_out
          }
        }
      }
      products_for_sale {
        id
        current_max_per_order
        is_sold_out
        promotions_for_sale {
          id
          current_max_per_order
          is_sold_out
        }
        active_product_variants {
          id
          current_max_per_order
          is_sold_out
        }
      }
    }
  }
`);
