import { HTMLAttributes, ReactNode, createContext, forwardRef, useContext, useEffect, useRef } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { Div, HR } from './Generic';
import { Link, LinkBlock } from './Nav';
import { Size, UIProps } from '../../theme/mixins';
import { Theme } from '../../theme';
import { isInViewport } from '../helpers';
import SC from './SC';
import useMediaDevice from '../useMediaDevice';
import useScroll from '../useScroll';
import useStickyElement, { UseStickyElement } from '../useStickyElement';

interface CardProps {
  hasPanels?: boolean;
  hasSidePanel?: boolean;
  placeholder?: boolean;
  active?: boolean;
  outline?: string;
  borderWidth?: number;
  highlight?: boolean;
  spacing?: Size;
}

export const Card = styled(Div)<SC<CardProps & UIProps>>`
  ${({
    sc: {
      hasPanels, spacing, placeholder, active, outline, borderWidth = 1, highlight, hasSidePanel, ...props
    } = {},
    theme,
  }) => {
    const padding = {
      mobile: {
        xs: 0.25,
        sm: 0.50,
        md: 0.75,
        lg: 1.00,
        xl: 1.25,
      },
      desktop: {
        xs: 0.50,
        sm: 0.75,
        md: 1.00,
        lg: 1.25,
        xl: 1.50,
      },
    };

    return css`
      display: block;
      border-radius: ${theme.borderRadiuses.xl}px;
      position: relative;
      transition: box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out, opacity 0.1s ease-in-out 0.15s;
      border-color: transparent;
      padding: ${padding.mobile[spacing || 'md'] * theme.gutter - (outline || highlight ? borderWidth : 0)}px;

      @media ${theme.devices.md} {
        padding: ${padding.desktop[spacing || 'md'] * theme.gutter - (outline || highlight ? borderWidth : 0)}px;
      }

      ${!outline && !highlight && css`
        background: ${theme.colors.white};
      `}

      ${!outline && !highlight && !placeholder && css`
        box-shadow: ${theme.shadows.sm};
      `}

      ${placeholder && css`
        background: rgba(0, 0, 0, .04);
      `}

      ${hasPanels && css`
        padding: 0 !important;

        > * {
          padding: ${padding.mobile[spacing || 'md'] * theme.gutter - (outline || highlight ? borderWidth : 0)}px;

          @media ${theme.devices.md} {
            padding: ${padding.desktop[spacing || 'md'] * theme.gutter - (outline || highlight ? borderWidth : 0)}px;
          }
        }

        > * + * {
          border-top: 1px solid rgba(0, 0, 0, .1);
        }
      `};

      ${hasSidePanel && css`
        padding: 0 !important;
        display: grid;
        grid-template-columns: 250px 1fr;

        @media ${theme.devices.sm} {
          grid-template-columns: 1fr;

          > div:first-of-type {
            border-radius: ${theme.borderRadiuses.xl}px ${theme.borderRadiuses.xl}px 0 0;
            border-bottom: 1px solid ${theme.colors.gray[200]};
          }

          > *:last-child {
            flex: 100%;
          }
        }

        @media ${theme.devices.md} {
          grid-template-columns: 250px 1fr;

          > div:first-of-type {
            border-radius: ${theme.borderRadiuses.xl}px 0 0 ${theme.borderRadiuses.xl}px;
            border-right: 1px solid ${theme.colors.gray[200]};
            border-bottom: 0;
          }

          > *:last-child {
            border-radius: 0 ${theme.borderRadiuses.xl}px ${theme.borderRadiuses.xl}px 0;
            flex: 100%;
          }
        }

        > div:first-of-type {
          background: ${theme.colors.gray[50]};
        }

        > * {
          padding: ${theme.gutter * 0.75}px;

          @media ${theme.devices.md} {
            padding: ${theme.gutter}px;
          }
        }
      `}

      ${(outline || highlight) && css`
        border: ${borderWidth}px solid ${theme.getColor(outline || 'gray.200')};

        ${highlight && css`
          animation: highlight-card 4s ease-in-out 0.1s forwards;

          @keyframes highlight-card {
            5% {
              border-color: ${theme.colors.secondary[400]};
              box-shadow: 0 0 0 ${theme.transparentize(theme.colors.secondary[400], 0.8)};
            }
            40% {
              box-shadow: 0 0 0 10px transparent;
            }
          }
        `}
      `}

      ${active && css`
        ${!outline && css`
          box-shadow: ${theme.shadows.lg};
        `}
      `}

      ${(hasPanels) && css`
        > :first-child {
          border-top-left-radius: ${theme.borderRadiuses.xl}px;
          border-top-right-radius: ${theme.borderRadiuses.xl}px;
        }

        > :last-child {
          border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
          border-bottom-right-radius: ${theme.borderRadiuses.xl}px;
        }
      `}

      ${HR} {
        @media ${theme.devices.md} {
          margin: ${theme.gutter}px 0;
        }
      }

      ${props && theme.useSpacing(props)}
      ${props && theme.useFlex(props)}
      ${props && theme.useText(props)}
    `;
  }}
`;

interface NavCardProps extends LinkProps, SC<CardProps & UIProps> {
  ariaLabel?: string;
}

export const NavCard = forwardRef(
  ({ sc, to, id, onClick, children, ariaLabel, ...props }: NavCardProps, ref: any) => (
    <NavCardContainer sc={{ spacing: 'sm', ...sc }} id={id} ref={ref}>
      <CardLink to={to} onClick={onClick} aria-label={ariaLabel} {...props} />
      {children}
    </NavCardContainer>
  ),
);

const CardLink = styled(Link)``;

const NavCardContainer = styled(Card)`
  ${({ sc: { hasPanels, outline, active } = {}, theme }) => css`
    ${!active && css`
      &:hover {
        ${!outline && css`
          box-shadow: ${theme.shadows.md};
        `}

        ${outline && css`
          border-color: ${theme.colors.link} !important;
        `}
      }
    `}

    > ${CardLink} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 200;
      border: none;
    }

    ${hasPanels && css`
      > ${CardLink} + * {
        border-top: none;
      }
    `}

    a, button, label {
      position: relative;
      z-index: 300;
    }
  `}
`;

interface CollapsibleNavCardProps {
  to: string;
  open: boolean;
  muted?: boolean;
  preview: ReactNode;
  body: ReactNode;
  id?: string;
  padding?: boolean;
  ariaLabel?: string
  sc?: CardProps & UIProps;
}

let collapsibleNavCardCounter = 0;

export const CollapsibleNavCard = ({
  to, open, muted = false, preview, body, id, padding = true, ariaLabel, sc,
}: CollapsibleNavCardProps) => {
  const { scrollTo } = useScroll();

  const idRef = useRef<string>();
  if (typeof idRef.current === 'undefined') {
    // Don't want to increment the counter unnecessarily
    idRef.current = id || `CollapsibleNavCard_${collapsibleNavCardCounter++}`;
  }

  const containerRef = useRef<any>();

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (open && containerRef.current && !isInViewport(containerRef.current, 0.2)) {
        // If less than 20% of the element is visible in the viewport, scroll to it
        scrollTo(idRef.current, { gutter: 0.5 });
      }
    });

    return () => window.clearTimeout(timer);
  }, [open, scrollTo]);

  const Container = open ? Card : NavCard;

  const props = {
    ...!open && {
      to,
      ariaLabel,
    },
  };

  const hasPanels = typeof sc?.hasPanels !== 'undefined' ? sc.hasPanels : true;

  return (
    <Container
      sc={{
        active: open, spacing: open ? 'lg' : 'sm', muted, hasPanels, ...sc,
      }}
      id={idRef.current}
      ref={containerRef}
      {...props}
    >
      {open && (
        <>
          <LinkBlock
            to={to}
            sc={{ padding: !padding ? 0 : undefined }}
            ariaLabel={ariaLabel}
          >
            <Div>
              {preview}
            </Div>
          </LinkBlock>
          <Div>
            {body}
          </Div>
        </>
      )}
      {!open && preview}
    </Container>
  );
};

const StickyCardContext = createContext<{ header?: UseStickyElement; }>({
  header: undefined,
});

interface StickyCardProps extends SC<CardProps & UIProps>, HTMLAttributes<HTMLDivElement> {
  aa: number;
}

export const StickyCard = ({ sc, style, ...props }: StickyCardProps) => {
  const device = useMediaDevice();
  const theme = useTheme() as Theme;

  const { current: id } = useRef(props.id || `StickyCard_${Math.round(Math.random() * 1000000)}`);

  const header = useStickyElement({
    containerId: id,
    fullWidth: false,
    offset: device.isSmall ? theme.scrollOffset.sm : theme.scrollOffset.md,
    scrollEndOffset: -12,
  });

  return (
    <StickyCardContext.Provider value={{ header }}>
      <Card id={id} sc={{ hasPanels: true, ...sc }} style={{ overflow: 'hidden', ...style }} {...props} />
    </StickyCardContext.Provider>
  );
};

const StickyPanelAnchor = styled(Div)`
  padding: 0;
`;

export const StickyCardHeader = ({ sc, children, ...props }: SC<UIProps> & HTMLAttributes<HTMLDivElement>) => {
  const { header } = useContext(StickyCardContext);

  return (
    <StickyPanelAnchor ref={header.anchorRef}>
      <StickyPanel ref={header.elementRef} isSticky={header.isSticky} {...header.position} {...props}>
        <Div sc={sc}>
          {children}
        </Div>
      </StickyPanel>
    </StickyPanelAnchor>
  );
};

interface StickyPanelProps {
  isSticky: boolean;
  top: number | string;
  right: number | string;
  bottom: number | string;
  left: number | string;
}

const StickyPanel = styled(Div)<StickyPanelProps>`
  ${({ theme, isSticky, top, right, bottom, left }) => css`
    background: white;
    z-index: 300;
    padding: 0;

    > ${Div} {
      padding: ${theme.gutter * 0.75}px;

      @media ${theme.devices.md} {
        padding: ${theme.gutter}px;
      }
    }

    ${isSticky && css`
      position: fixed;
      top: ${typeof top === 'number' ? `${top}px` : top};
      right: ${typeof right === 'number' ? `${right}px` : right};
      bottom: ${typeof bottom === 'number' ? `${bottom}px` : bottom};
      left: ${typeof left === 'number' ? `${left}px` : left};
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    `}
  `}
`;
