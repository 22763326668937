import { ChevronLeft } from 'react-feather';
import {
  Link as RouterLink, LinkProps as RouterLinkProps, NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import React, { ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Button } from './Button';
import { Div, Span } from './Generic';
import { Icon } from './Icon';
import { UIProps } from '../../theme/mixins';
import SC from './SC';
import theme from '../../theme';

interface StyledLinkProps extends UIProps {
  secondary?: boolean;
  disabled?: boolean;
}

export const A = styled.a.attrs((attributes) => ({
  ...attributes,
  ...attributes.role === 'button' && !attributes.href && attributes.onClick && !attributes['aria-disabled'] && {
    tabIndex: attributes.tabIndex || 0,
    onKeyDown: attributes.onKeyDown || ((event: KeyboardEvent) => {
      if (event.key === ' ') {
        event.preventDefault();
        (event.target as HTMLElement).click();
      }
    }),
  },
}))<SC<StyledLinkProps>>`
  ${({ sc: props = {}, href, onClick, theme }) => css`
    ${useLink(props, theme)};

    ${!href && !onClick && css`
      cursor: default;
    `}
  `}
`;

export interface LinkProps extends SC<StyledLinkProps>, RouterLinkProps {
  disabled?: boolean;
}

const useLink = ({ secondary, disabled, ...props }: StyledLinkProps, theme: any) => css`
  cursor: pointer;

  ${secondary && css`
    color: ${theme.colors.gray[800]};

    &:hover {
      color: ${theme.colors.link} !important;
    }
  `}

  ${disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}

  ${theme.useMixins(props)}
`;

export const Link = styled(RouterLink).withConfig({
  shouldForwardProp: (prop) => !['sc'].includes(prop),
})<LinkProps>`
  ${({ disabled = false, sc: props = {}, theme }) => css`
    ${useLink({ disabled, ...props }, theme)};
  `}
`;

export interface NavLinkProps extends SC<StyledLinkProps>, RouterNavLinkProps {
  disabled?: boolean;
}

export const NavLink = styled(RouterNavLink).withConfig({
  shouldForwardProp: (prop) => !['sc'].includes(prop),
}) <NavLinkProps>`
  ${({ disabled = false, sc: props = {}, theme }) => css`
    ${useLink({ disabled, ...props }, theme)};
  `}
`;

interface NavCardProps extends LinkProps, SC<StyledLinkProps> { }

interface LinkBlockProps extends Partial<NavCardProps> {
  to?: string;
  href?: string;
  onClick?: () => void;
  target?: string;
  rel?: string;
  ariaLabel?: string;
}

export const LinkBlock = ({
  sc, to, href, onClick, id, title, children, target, rel, ariaLabel, style,
}: LinkBlockProps) => (
  <LinkBlockContainer id={id} sc={sc} style={style}>
    {to && <LinkBlockLink to={to} title={title} onClick={onClick} aria-label={ariaLabel} />}
    {!to && (href || onClick) && (
      <LinkBlockAnchor
        href={href}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        title={title}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
      />
    )}
    {children}
  </LinkBlockContainer>
);

const LinkBlockLink = styled(Link)`
  cursor: pointer;
`;

const LinkBlockAnchor = styled(A)`
  cursor: pointer;
`;

export const LinkBlockContainer = styled(Div)`
    position: relative;

    > ${LinkBlockLink}, ${LinkBlockAnchor} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 200;
    }

    a, button, label {
      position: relative;
      z-index: 300;
    }
`;

interface TabProps {
  color?: string;
  activeColor?: string;
  activeBorderColor?: string;
}

export const Tab = styled(NavLink)<SC<TabProps> & NavLinkProps>`
  ${({ sc: { color = 'gray.600', activeColor = 'gray.900', activeBorderColor = 'secondary.500' } = {}, theme }) => css`
    display: inline-block;
    padding: ${theme.gutter / 3}px 0;
    color: ${theme.getColor(color)};
    transition: border-color 0.1s ease-in-out;
    border-bottom: 3px solid transparent;

    &:hover {
      color: ${theme.getColor(activeColor)};
      border-color: ${theme.colors.gray[250]};
    }

    &.active {
      color: ${theme.getColor(activeColor)} !important;
      border-color: ${theme.getColor(activeBorderColor)};
    }
  `}
`;

interface TabsContainerProps {
  border?: boolean;
  borderColor?: string;
  fade?: boolean;
}

export const TabsContainer = styled(Div) <SC<TabsContainerProps & UIProps>>`
  ${({ sc: { border, borderColor = 'gray.200' } = {}, theme }) => css`
    position: relative;

    ${border && css`
      border-bottom: 3px solid ${theme.getColor(borderColor)};

      ${InnerTabs} {
        margin-bottom: -3px;
        padding-left: 0;
        padding-right: 0;
      }
    `}
  `}
`;

export const InnerTabs = styled(Div) <SC<TabsContainerProps & UIProps>>`
  ${({ sc: { border } = {}, theme }) => css`
    padding: 0;
    font-weight: 500;
    position: relative;
    width: 100%;
    scroll-behavior: smooth;
    overflow: scroll;
    white-space: nowrap;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
      background: transparent;
    }

    ${!border && css`
      @media ${theme.devices.sm} {
        padding-left: ${theme.gutter / 2}px;
      }
    `}

    @media ${theme.devices.md} {
      padding-left: 0;
      overflow: auto;
    }

    ${Tab} {
      padding: ${theme.gutter / 2}px 0;
      white-space: nowrap;
      position: relative;
    }

    ${Tab} + ${Tab} {
      margin-left: ${theme.gutter * (2 / 3)}px;
    }

    ${!border && css`
      /* Trick to add padding to the end of overflown container.*/
      ${Tab}:last-child:after {
        content: '';
        display: block;
        position: absolute;
        right: -${theme.gutter}px;
        width: ${theme.gutter}px;
        height: 1px;
      }
    `}
  `}
`;

const TabOverflowFadeLeft = styled.div`
  ${({ theme }) => css`
    width: ${theme.gutter}px;
    height: 100%;
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    position: absolute;
    z-index: 2;
    left: 0;

    @media ${theme.devices.md} {
      display: none;
    }
  `}
`;

const TabOverflowFadeRight = styled.div`
  ${({ theme }) => css`
    width: ${theme.gutter}px;
    height: 100%;
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    z-index: 2;
    right: 0;

    @media ${theme.devices.md} {
      display: none;
    }
  `}
`;

interface TabsProps {
  sc?: TabsContainerProps & UIProps;
  children?: ReactNode;
}

export const Tabs = ({ children, sc = { fade: true } }: TabsProps) => {
  const tabsRef = useRef<HTMLDivElement>(null);

  // Find active child and scroll to this child.
  useEffect(() => {
    if (tabsRef.current) {
      const activeChild = tabsRef.current.querySelector<HTMLElement>('.active');

      if (activeChild) {
        const parent = activeChild.parentNode as HTMLElement;

        if (parent) {
          parent.scrollLeft = activeChild.offsetLeft - parent.offsetLeft - theme.gutter;
        }
      }
    }
  }, []);

  return (
    <TabsContainer sc={sc}>
      {sc.fade && !sc.border && <TabOverflowFadeLeft />}
      {sc.fade && !sc.border && <TabOverflowFadeRight />}
      <InnerTabs sc={{ border: sc.border }} ref={tabsRef}>
        {children}
      </InnerTabs>
    </TabsContainer>
  );
};

interface BreadCrumbProps {
  to: string;
  children?: React.ReactNode;
}

export const BreadCrumbContainer = styled(Div)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    font-weight: 500;

    &:hover svg {
      stroke: ${theme.colors.secondary[500]};
    }

    ${NavLink} {
      color: inherit;
    }
  `}
`;

export const BreadCrumb = ({ to, children }: BreadCrumbProps) => (
  <BreadCrumbContainer>
    <Icon sc={{ mr: 1 }}>
      <ChevronLeft />
    </Icon>
    <NavLink to={to}>
      {children}
    </NavLink>
  </BreadCrumbContainer>
);

export const NavItem = styled(Span)``;

export const Nav = styled(Div)`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.colors.gray[200]};

    ${NavLink}, ${Button}, ${NavItem} {
      color: ${theme.colors.gray[600]};
      font-weight: 500;
      display: block !important;
      border-top-left-radius: ${theme.borderRadiuses.md}px;
      border-bottom-left-radius: ${theme.borderRadiuses.md}px;
      padding: ${theme.gutter / 3}px !important;
      margin-right: -2px;
      border-right: 2px solid ${theme.colors.gray[200]};
      box-shadow: none;

      &:hover {
        background: ${theme.transparentize(theme.colors.gray[100], 0.5)};
        color: ${theme.colors.gray[800]};
      }

      &.active {
        border-color: ${theme.colors.secondary[500]};
        background: ${theme.colors.gray[100]};
        color: ${theme.colors.secondary[500]};
      }
    }

    ${Button} {
      background: transparent;
      border: 0;
      text-align: left;
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > * + * {
      margin-top: 1px;
    `}
`;
