import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import difference from 'lodash/difference';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

import { decodeUrl, encodeUrl } from './helpers';
import useMemoizedValue from './useMemoizedValue';

interface ParseHelpers<T = any> {
  /**
   * Safely returns a string if the value is a non-empty string, or the default value if not.
   */
  string: (value: unknown, defaultValue?: string | null) => string | null;
  /**
   * Safely returns an integer if the value is an integer, or the default value if not.
   */
  integer: (value: unknown, defaultValue?: number | null, min?: number | null) => number | null;
  /**
   * Safely returns a boolean if the value is a boolean, or the default value if not.
   */
  boolean: (value: unknown, defaultValue?: boolean | null) => boolean | null;
  /**
   * Safely returns an array containing elements of the desired type according to check(), or the default value if not.
   */
  array: (value: unknown, check: (value: unknown) => T | null, defaultValue?: T[] | null) => T[] | null;
  /**
   * Safely returns an array containing elements of the desired type according to check(), or the default value if not.
   */
  object: (value: unknown, check: (value: UrlState) => T | null, defaultValue?: T | null) => T | null;
}

const parseHelpers: ParseHelpers = {
  string: (value, defaultValue = null) => {
    if (typeof value === 'string' && value !== '') {
      return value;
    }

    return defaultValue;
  },
  boolean: (value, defaultValue = null) => {
    if (value === 'true') {
      return true;
    }

    if (value === 'false') {
      return false;
    }

    return defaultValue;
  },
  integer: (value, defaultValue = null, min = 0) => {
    if (typeof value === 'string') {
      let parsed = parseInt(value, 10);

      if (!Number.isNaN(parsed)) {
        if (typeof min === 'number') {
          parsed = Math.max(parsed, min);
        }

        return parsed;
      }
    }

    return defaultValue;
  },
  array: (value, check, defaultValue = []) => {
    if (isArray(value)) {
      return value.map(check).filter((value) => value !== null);
    }

    return defaultValue;
  },
  object: (value, check, defaultValue = {}) => {
    if (isObject(value)) {
      return check(value);
    }

    return check(defaultValue);
  },
};

interface UrlState {
  [key: string]: any;
  [key: number]: any;
}

type UpdateUrlState<T = UrlState> = (value: T) => T;

export type SetUrlState<T = UrlState> = (
  newValue: T | UpdateUrlState<T>, replace?: boolean
) => void;

interface UseUrlProps<T = any> {
  parse: (rawState: UrlState, parseHelpers: ParseHelpers) => T;
  onChange?: (urlState: T, rawState: any) => void;
}

/**
 * Compares two strings of query params and returns whether they are the same if order of the params
 * is not important.
 */
const encodedParamsAreEqual = (a: string, b: string): boolean => {
  const aParts = a.split('&');
  const bParts = b.split('&');

  return aParts.length === bParts.length && difference(aParts, bParts).length === 0;
};

function useUrlState<T = UrlState>({ parse, onChange }: UseUrlProps<T>) {
  const history = useHistory();
  const { search } = useLocation();

  const urlState = useMemoizedValue(parse(decodeUrl(search.substr(1)), parseHelpers));
  const searchRef = useRef(search);

  useEffect(() => history.listen((location, action) => {
    if (searchRef.current !== location.search) {
      searchRef.current = location.search;

      const decodedState = decodeUrl(location.search.substr(1));

      if (action !== 'REPLACE') {
        onChange?.(parse(decodedState, parseHelpers), decodedState);
      }
    }
    // No useEffect dependencies to prevent that the onChange handler becomes stale
  }));

  const mergeUrlParams = useCallback(
    (updateState: T | UpdateUrlState<T>) => {
      const { search } = history.location;
      const oldState = parse(decodeUrl(search.substr(1)), parseHelpers);

      const newState = isFunction(updateState)
        ? updateState(oldState)
        : { ...oldState, ...updateState };

      return encodeUrl(newState);
    },
    [history, parse],
  );

  const setUrlState = useCallback(
    (updateState: T | UpdateUrlState<T>, replace: boolean = false) => {
      const { search } = history.location;
      const encodedState = mergeUrlParams(updateState);

      if (!encodedParamsAreEqual(encodedState, search.substr(1))) {
        if (replace) {
          history.replace({
            search: encodedState,
          });
        } else {
          history.push({
            search: encodedState,
          });
        }
      }
    },
    [mergeUrlParams, history],
  );

  const parseUrlState = useCallback(
    (search: string) => parse(decodeUrl(search.replace('?', '')), parseHelpers),
    [parse],
  );
  const helpers = {
    parseUrlState,
    mergeUrlParams,
  };

  return [
    urlState,
    setUrlState,
    helpers,
  ] as [
    T,
    SetUrlState<T>,
    any,
  ];
}

export default useUrlState;
