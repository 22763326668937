import styled, { css } from 'styled-components';

import { CheckboxLabel } from './Checkbox';
import { Div, Span } from './Generic';
import { Icon } from './Icon';
import { Paragraph } from './Text';
import { RadioLabel } from './Radio';
import { SelectWrapper } from './Select';
import { SpacingProps, UIProps } from '../../theme/mixins';
import SC from './SC';

interface InputGroupProps extends SpacingProps, UIProps {
  invalid?: boolean;
  valid?: boolean;
  touched?: boolean;
}

const InputGroup = styled(Div) <SC<InputGroupProps>>`
  ${({ sc: { invalid, valid, touched, ...props } = { invalid: false }, theme }) => css`
    border: none;
    padding: 0;
    margin-bottom: ${theme.gutter}px;

    ${ErrorMessagesContainer} {
      margin-top: ${theme.gutter / 4}px;
      display: none;
      color: ${theme.colors.error[500]};
    }

    ${(invalid || (valid === false && touched)) && css`
      ${InputLabel} {
        color: ${theme.colors.error[500]};
      }

      input, select, ${RadioLabel}, ${CheckboxLabel} {
        border-color: ${theme.colors.error[500]} !important;
      }

      ${ErrorMessagesContainer} {
        display: block;
      }

      ${ErrorMessagesContainer} + * {
        margin-top: ${theme.gutter / 4}px;
      }
    `}

    ${valid && css`
      ${RequiredMark} {
        ::before {
          color: ${theme.colors.gray[400]};
        }
      }
    `}

    ${props && theme.useSpacing(props)}
  `}
`;

export const InputLabel = styled.label<SC<UIProps>>`
  ${({ sc: props, theme }) => css`
    color: ${theme.colors.gray[800]};
    font-weight: 600;
    display: block;
    margin-bottom: 0;

    a {
      font-weight: normal;
    }

    ${theme.useMixins(props)}
  `}
`;

interface RequiredMarkProps extends UIProps {
  valid?: boolean;
}

export const RequiredMark = styled(Span)<SC<RequiredMarkProps>>`
  ${({ sc: { valid = false } = {}, theme }) => css`
    ::before {
      content: '*';
      color: ${valid ? theme.colors.gray[400] : theme.colors.error[500]};
    }
  `}
`;

export const InputDescription = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[600]};
    margin: 0;
  `}
`;

export const InputWrapper = styled(Div)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadiuses.md}px;
    position: relative;

    > input, > select, > ${SelectWrapper} select {
      z-index: 2;
      background: transparent;
    }

    > span, > label {
      display: flex;
      align-items: center;
    }

    > span, > button, > label {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0 10px;

      > a {
        height: 100%;
        display: flex;
        align-items: center;
      }

      &:first-child {
        left: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        right: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    > ${InputLabel} {
      font-weight: normal;
    }

    ${Icon} {
      margin-bottom: 0;
    }
  `}
`;

export const ErrorMessagesContainer = styled(Div)``;

export default InputGroup;
