import { darkenColor, lightenColor, mix, saturate } from './helpers';

export const defaultSecondary = '#414aaf';

const getVariables = ({
  primary = '#64c2a1',
  secondary = defaultSecondary,
  tertiary = '#1fbff2',
  gray = '#65717d',
  error = '#cc3633',
  info = '#f2cf5c',
  bodyBackground = 'gray.75',
  scrollOffset = {
    sm: 0,
    md: 0,
  },
  breakpoints = {
    sm: 0,
    md: 720,
    lg: 1280,
    xl: 2000,
  },
} = {}) => ({
  colors: {
    primary: {
      25: lightenColor(primary, 60),
      50: lightenColor(primary, 45),
      100: lightenColor(primary, 30),
      200: lightenColor(primary, 15),
      300: primary,
      400: darkenColor(primary, 10),
      500: darkenColor(primary, 20),
      600: darkenColor(primary, 30),
      700: darkenColor(primary, 40),
      800: darkenColor(primary, 50),
      900: darkenColor(primary, 60),
    },
    secondary: {
      25: saturate(mix(secondary, 'white', 0.95), 72),
      50: saturate(mix(secondary, 'white', 0.93), 68),
      75: saturate(mix(secondary, 'white', 0.87), 65),
      100: saturate(mix(secondary, 'white', 0.8), 60),
      150: saturate(mix(secondary, 'white', 0.7), 53),
      200: saturate(mix(secondary, 'white', 0.6), 45),
      300: saturate(mix(secondary, 'white', 0.4), 30),
      400: saturate(mix(secondary, 'white', 0.2), 15),
      500: secondary,
      600: saturate(mix(secondary, 'black', 0.2), 15),
      700: saturate(mix(secondary, 'black', 0.4), 30),
      800: saturate(mix(secondary, 'black', 0.6), 45),
      900: saturate(mix(secondary, 'black', 0.8), 60),
    },
    atleta_secondary: {
      50: saturate(mix(defaultSecondary, 'white', 0.93), 68),
      100: saturate(mix(defaultSecondary, 'white', 0.8), 60),
      150: saturate(mix(defaultSecondary, 'white', 0.7), 53),
      200: saturate(mix(defaultSecondary, 'white', 0.6), 45),
      300: saturate(mix(defaultSecondary, 'white', 0.4), 30),
      400: saturate(mix(defaultSecondary, 'white', 0.2), 15),
      500: defaultSecondary,
      600: saturate(mix(defaultSecondary, 'black', 0.2), 15),
      700: saturate(mix(defaultSecondary, 'black', 0.4), 30),
      800: saturate(mix(defaultSecondary, 'black', 0.6), 45),
      900: saturate(mix(defaultSecondary, 'black', 0.8), 60),
    },
    tertiary: {
      100: lightenColor(tertiary, 70),
      200: lightenColor(tertiary, 40),
      300: lightenColor(tertiary, 20),
      400: tertiary,
      500: darkenColor(tertiary, 14),
      600: darkenColor(tertiary, 28),
      700: darkenColor(tertiary, 42),
      800: darkenColor(tertiary, 56),
      900: darkenColor(tertiary, 70),
    },
    gray: {
      50: saturate(lightenColor(gray, 120), 110),
      75: saturate(lightenColor(gray, 116), 95),
      100: saturate(lightenColor(gray, 113), 75),
      150: saturate(lightenColor(gray, 106), 65),
      200: saturate(lightenColor(gray, 100), 60),
      250: saturate(lightenColor(gray, 90), 45),
      300: saturate(lightenColor(gray, 80), 30),
      400: saturate(lightenColor(gray, 45), 15),
      500: gray,
      600: saturate(darkenColor(gray, 20), 15),
      700: saturate(darkenColor(gray, 40), 30),
      800: saturate(darkenColor(gray, 60), 45),
      900: saturate(darkenColor(gray, 80), 60),
    },
    black: '#000000',
    white: '#ffffff',
    body: '#2e313f',
    link: darkenColor(tertiary, 28),
    success: darkenColor(primary, 15),
    error: {
      25: lightenColor(error, 95),
      50: lightenColor(error, 92),
      100: lightenColor(error, 88),
      200: lightenColor(error, 66),
      300: lightenColor(error, 44),
      400: lightenColor(error, 22),
      500: error,
      600: darkenColor(error, 22),
      700: darkenColor(error, 44),
      800: darkenColor(error, 66),
      900: darkenColor(error, 88),
    },
    transparent: 'transparent',
    info: {
      100: lightenColor(info, 40),
      200: lightenColor(info, 30),
      300: lightenColor(info, 20),
      400: lightenColor(info, 10),
      500: info,
      600: darkenColor(info, 20),
      700: darkenColor(info, 40),
      800: darkenColor(info, 60),
      900: darkenColor(info, 80),
    },
    code: '#d95543',
    supporta_emerald: {
      50: '#daf6e8',
      100: '#b8ecd2',
      200: '#79d9a9',
      300: '#42c684',
      400: '#14b363',
      500: '#00a050',
      600: '#008d47',
      700: '#007a3d',
      800: '#006734',
      900: '#00542a',
    },
  },

  // Body BG
  bodyBackground,

  // Pixels
  borderRadiuses: {
    sm: 4,
    md: 6,
    lg: 8,
    xl: 12,
  },

  fontSizes: {
    h6: 13 / 16, // Rem
    h5: 15 / 16, // Rem
    h4: 1, // Rem
    h3: 18 / 16, // Rem
    h2: 24 / 16, // Rem
    h1: 30 / 16, // Rem
    body: 1, // Rem
    root: '16px', // Px
  },

  breakpoints,

  devices: {
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
  },

  gutter: 24,

  fontFamilies: {
    body: "'Avenir Next W01', 'Avenir Next', Avenir, Helvetica, Arial, sans-serif",
    headings: "'Avenir Next W01', 'Avenir Next', Avenir, Helvetica, Arial, sans-serif",
    code: 'Monaco, monospace',
  },

  // Copied from Tailwind
  shadows: {
    xs: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    sm: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    md: '0 2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    lg: '0 7px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },

  containerWidth: {
    full: 1200,
    compact: 800,
  },

  // Used to add an extra offset to scrollTo(), to account for e.g. floating nav bars
  scrollOffset,

  // Only used by back-end
  navBarHeight: {
    sm: 70,
    md: 60,
  },

  zIndices: {
    notificationCenter: 1001,
  },
});

export default getVariables;
