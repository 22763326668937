import {
  Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { ThemeOptions, getTheme } from '../../theme';
import { defaultSecondary } from '../../theme/variables';

interface ThemeProviderProps {
  options?: ThemeOptions;
  children: ReactNode;
}

const ThemeProvider = ({ options: initialOptions = {}, children }: ThemeProviderProps) => {
  const [options, setOptions] = useState<ThemeOptions>(initialOptions);
  const theme = useMemo(() => getTheme(options), [options]);

  return (
    <StyledThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ options, setOptions }}>
        {children}
      </ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

interface ThemeContextValue {
  options?: ThemeOptions;
  setOptions?: Dispatch<SetStateAction<ThemeOptions>>;
}

export const ThemeContext = createContext<ThemeContextValue>({});

export const useBrandColor = (brandColor: string | null, active: boolean = true) => {
  const [themeInitialized, setThemeInitialized] = useState(false);
  const { setOptions } = useContext(ThemeContext);

  useEffect(() => {
    if (active) {
      setOptions((options) => ({
        ...options,
        secondary: brandColor || defaultSecondary,
      }));
      setThemeInitialized(true);
    }
  }, [active, setOptions, brandColor]);

  return themeInitialized;
};

export default ThemeProvider;
