import { Copy } from 'react-feather';
import { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash/keyBy';

import { ParticipantAttributes, RegistrationInput } from '__generated__/graphql';

import { ErrorBag, Update, concatPrefix, containsKeyPrefix, filterPrefix } from '../helpers';
import { ParticipantField } from '../ParticipantFields/ParticipantFieldInputProps';
import { ParticipantFieldEntry, getDisabledChoices } from '../ParticipantFields/helpers';
import EmailInput from '../ParticipantAttributes/Attributes/EmailInput';
import NameInput from '../ParticipantAttributes/Attributes/NameInput';
import ParticipantAttributeInput from '../ParticipantAttributes/ParticipantAttributeInput';
import ParticipantFieldInput from '../ParticipantFields/ParticipantFieldInput';
import UI from '../UI';

export interface AssignmentFormProps {
  registration: RegistrationInput;
  onChange: (update: Update<RegistrationInput>) => void;
  onBlur?: (key: string) => void;
  touched?: any;
  errors?: ErrorBag;
  candidates?: RegistrationInput[];
  registrationAttributes: ParticipantAttributes[];
  canChangeEmail?: boolean;
  reassignable?: boolean;
  allowAssignLater: boolean;
  required?: boolean;
  enabledParticipantFields: ParticipantField[];
  existingFieldEntries?: ParticipantFieldEntry[];
  emailDescription?: ReactNode;
  updateCustomErrors?: (errors: ErrorBag, prefix: string) => void;
  updateValidating?: (loading: string[], prefix: string) => void;
}

const AssignmentForm = ({
  registration,
  onChange,
  onBlur,
  touched,
  errors = {},
  candidates = [],
  registrationAttributes,
  canChangeEmail = true,
  reassignable = true,
  allowAssignLater,
  required = true,
  enabledParticipantFields,
  existingFieldEntries = [],
  emailDescription,
  updateCustomErrors,
  updateValidating,
}: AssignmentFormProps) => {
  const { t } = useTranslation('common');

  const assigned = !!registration.participant;

  /** If candidates are given, points to the index of the selected candidate */
  const [candidateIndex, setCandidateIndex] = useState(-1);

  const assign = () => {
    onChange((registration) => ({
      ...registration,
      participant: {},
      details: {},
      fields: [],
    }));

    setCandidateIndex(-1);
  };

  const unassign = () => {
    onChange((registration) => ({
      ...registration,
      participant: null,
      details: null,
      fields: null,
    }));

    setCandidateIndex(-1);
  };

  const selectCandidate = (index: number) => {
    const newIndex = index === candidateIndex ? -1 : index;
    const candidate = candidates[newIndex];

    if (candidate) {
      onChange((registration) => ({
      // Keep purchase info
        ...registration,
        // Copy participant from the candidate
        participant: { ...candidate.participant },
        // Copy details from the candidate
        details: { ...candidate.details },
        // Copy participant fields from the candidate, if present (minus IDs)
        fields: candidate.fields?.map(({ id, ...fieldEntry }) => ({
          ...fieldEntry,
        })) || registration.fields,
      }));
    }

    setCandidateIndex(newIndex);
  };

  const keyedFields = keyBy(enabledParticipantFields, 'id');
  const keyedExistingFieldEntries = keyBy(existingFieldEntries, 'id');

  const shouldRunExternalValidation = !containsKeyPrefix(errors, 'participant')
    && !containsKeyPrefix(errors, 'details');

  return (
    <UI.FormGrid>
      {allowAssignLater && (
        <UI.InputGroup>
          <UI.FormGrid sc={{ gutter: 0.5 }}>
            <UI.Checkbox
              checked={!assigned}
              onChange={assigned ? unassign : assign}
              id="AssignLater"
            >
              {t('assignment_form.assign_later')}
            </UI.Checkbox>
          </UI.FormGrid>
        </UI.InputGroup>
      )}

      {assigned && (
        <UI.FadeIn>
          <UI.FormGrid>
            {candidates.length > 0 && (
              <UI.InputGroup>
                <UI.InputWrapper>
                  <UI.Icon sc={{ muted: true }}>
                    <Copy />
                  </UI.Icon>
                  <UI.Select
                    value={`${candidateIndex}`}
                    onChange={(event) => selectCandidate(parseInt(event.target.value, 10))}
                    sc={{ hasValue: candidateIndex !== -1, pl: 5 }}
                    aria-label={t('assignment_form.copy_details_from')}
                  >
                    <option value={-1}>
                      {t('assignment_form.copy_details_from')}
                    </option>
                    {candidates.map((candidate, index) => (
                      <option value={index} key={index}>
                        {index === candidateIndex && `${t('assignment_form.copied_from')} `}
                        {candidate.participant.first_name}
                        {' '}
                        {candidate.participant.last_name}
                        {' ('}
                        {candidate.participant.email}
                        )
                      </option>
                    ))}
                  </UI.Select>
                </UI.InputWrapper>
              </UI.InputGroup>
            )}

            <Fragment key={candidateIndex}>
              {reassignable && (
                <>
                  <NameInput
                    value={registration.participant}
                    onChange={
                      (participant) => onChange((registration) => ({
                        ...registration,
                        participant: { ...registration.participant, ...participant },
                      }))
                    }
                    required
                    onBlur={(field) => onBlur?.(`participant.${field}`)}
                    touched={touched?.participant}
                    errors={filterPrefix(errors, 'participant')}
                  />

                  <EmailInput
                    value={registration.participant}
                    onChange={
                      (participant) => onChange((registration) => ({
                        ...registration,
                        participant: { ...registration.participant, ...participant },
                      }))
                    }
                    onBlur={(field) => onBlur?.(`participant.${field}`)}
                    touched={touched?.participant}
                    errors={filterPrefix(errors, 'participant')}
                    description={emailDescription}
                    disabled={!canChangeEmail}
                    required
                  />
                </>
              )}

              {registrationAttributes.map((attribute) => (
                <ParticipantAttributeInput
                  attribute={attribute}
                  value={registration.details}
                  onChange={
                    (details) => onChange((registration) => ({
                      ...registration,
                      details: {
                        ...registration.details,
                        ...details,
                      },
                    }))
                  }
                  onBlur={(field) => onBlur?.(`details.${field}`)}
                  touched={touched?.details}
                  errors={filterPrefix(errors, 'details')}
                  required={required}
                  key={attribute}
                />
              ))}

              {registration.fields.map((value, index) => keyedFields[value.participant_field.id] && (
                <ParticipantFieldInput
                  value={value}
                  onChange={
                    (newValue) => onChange((registration) => ({
                      ...registration,
                      fields: registration.fields.map((oldValue, fieldIndex) => (
                        index === fieldIndex ? {
                          ...oldValue,
                          ...newValue,
                        } : oldValue
                      )),
                    }))
                  }
                  onBlur={() => onBlur?.(`fields.${index}`)}
                  touched={!!touched?.fields?.[index]}
                  field={keyedFields[value.participant_field.id]}
                  disabledChoices={keyedExistingFieldEntries[value.id] ? getDisabledChoices(
                    keyedFields[value.participant_field.id],
                    keyedExistingFieldEntries[value.id],
                  ) : []}
                  errors={filterPrefix(errors, `fields.${index}`)}
                  updateCustomErrors={(errors, prefix) => updateCustomErrors?.(errors, concatPrefix(`fields.${index}`, prefix))}
                  updateValidating={(validating, prefix) => updateValidating?.(validating, concatPrefix(`fields.${index}`, prefix))}
                  shouldRunExternalValidation={shouldRunExternalValidation}
                  registration={registration}
                  key={value.participant_field.id}
                />
              ))}
            </Fragment>
          </UI.FormGrid>
        </UI.FadeIn>
      )}
    </UI.FormGrid>
  );
};

export default AssignmentForm;
