import styled, { css } from 'styled-components';

import { UIProps } from '../../theme/mixins';
import SC from '../UI/SC';
import UI from '../UI';

interface SellableLabelProps {
  brandColor?: string;
  error?: boolean;
  type?: 'ticket' | 'product';
}

const SellableLabel = styled(UI.Label)<SellableLabelProps & SC<UIProps>>`
  ${({ brandColor, error = false, type = 'ticket', theme }) => css`
    background: ${brandColor
    ? theme.saturate(theme.mix(brandColor, 'white', 0.9), 65)
    : theme.getColor(error ? 'gray.100' : (type === 'ticket' ? 'secondary.75' : 'transparent'))};
    color: ${brandColor
    ? theme.saturate(theme.mix(brandColor, 'black', 0.2), 15)
    : theme.getColor(error ? 'gray.600' : (type === 'ticket' ? 'secondary.600' : 'gray.600'))};
    ${type === 'product' && css`
      box-shadow: inset 0 0 0 1px ${theme.colors.gray[200]};
    `}
    font-size: 0.875em;
    font-weight: 500;
    display: inline-block;
  `}
`;

export default SellableLabel;
