import { ReactNode, useEffect } from 'react';
import { X } from 'react-feather';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { A, Link } from './Nav';
import { Card } from './Card';
import { Div } from './Generic';
import { Icon } from './Icon';
import SC from './SC';

interface ModalProps {
  maxWidth?: number;
  close?: (() => void) | string;
  children: ReactNode;
}

export const Modal = ({ maxWidth = 400, close, children }: ModalProps) => {
  useEffect(() => {
    const { overflow } = document.body.style;

    document.body.style.overflow = 'hidden';

    return () => { document.body.style.overflow = overflow; };
  }, []);

  const padding = maxWidth > 400 ? 6 : 4;

  return (
    <ModalOverlay id="Modal">
      <motion.div
        animate={{ y: 0, opacity: 1 }}
        initial={{ y: 50, opacity: 0 }}
        transition={{ type: 'tween', duration: 0.1, ease: 'easeInOut' }}
      >
        <ModalContainer style={{ maxWidth }}>
          <ModalContent role="dialog" sc={{ active: true, padding }}>
            {close && <ModalCloseButton close={close} padding={padding} />}
            {children}
          </ModalContent>
        </ModalContainer>
      </motion.div>
    </ModalOverlay>
  );
};

interface ModalCloseButtonProps {
  close: (() => void) | string;
  padding?: number;
}

export const ModalCloseButton = ({ close, padding = 4 }: ModalCloseButtonProps) => {
  const to = typeof close === 'string' ? close : undefined;
  const onClick = typeof close === 'function' ? close : undefined;
  const Button = to ? Link : A;

  return (
    <ModalCloseButtonContainer sc={{ padding }}>
      <Button to={to} onClick={onClick} replace>
        <Icon>
          <X />
        </Icon>
      </Button>
    </ModalCloseButtonContainer>
  );
};

interface ModalCloseButtonContainerProps {
  padding?: number;
}

const ModalCloseButtonContainer = styled.span<SC<ModalCloseButtonContainerProps>>`
  ${({ sc: { padding = 4 } = {}, theme }) => css`
    position: absolute;
    right: ${(theme.gutter / 4) * (padding - 2)}px;
    top: ${(theme.gutter / 4) * (padding - 2)}px;
    z-index: 100;

    a {
      display: inline-block;
      font-size: 1.25em;
      color: ${theme.colors.gray[400]};
      padding: ${theme.gutter / 2}px;
      cursor: pointer;
      transition: color 0.15s ease-in-out;
      line-height: 1;

      &:hover {
        color: ${theme.colors.gray[800]};
      }
    }
  `}
`;

const ModalOverlay = styled(Div)`
  background: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
`;

const ModalContainer = styled(Div)`
  margin: 0 auto;
  padding: 10vh 0;
  transition: all 0.15s ease-in-out;
`;

const ModalContent = styled(Card)`
  position: relative;
`;

export default undefined;
