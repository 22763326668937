import { Locale } from '__generated__/graphql';

// Ordered set of months.
export const months = [
  'january', 'february', 'march', 'april', 'may', 'june',
  'july', 'august', 'september', 'october', 'november', 'december',
];

const defaultLocale = {
  // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  dateFormats: {
    internal_date: 'yyyy-MM-dd',
    internal_time: 'HH:mm:ss',
    internal_date_time: 'yyyy-MM-dd HH:mm:ss',

    input_date: 'dd/MM/yyyy',
    input_time: 'HH:mm',
    input_date_time: 'dd/MM/yyyy HH:mm',

    display_date: 'MMMM d, yyyy',
    display_time: 'HH:mm',
    display_date_time: 'MMMM d, yyyy, HH:mm',

    display_date_without_year: 'MMMM d',
    display_date_time_without_year: 'MMMM d, HH:mm',

    display_compact_date: 'dd/MM/yyyy',
    display_compact_date_time: 'dd/MM/yyyy, HH:mm',

    display_month: 'MMMM yyyy',
    display_day_of_week: 'EEEE',
    display_time_slot_date_time: 'EEEE, MMMM d, HH:mm',
    display_time_slot_date: 'EEEE, MMMM d',

    date_of_birth: 'MMMM d, yyyy',

    timestamp: 't',
  },
  thousandsSeparator: ',',
  decimalSeparator: '.',
};

const config = {
  baseUrl: window.location.origin, // Without trailing slash,
  backendPath: '/b',
  locales: [
    Locale.de,
    Locale.en,
    Locale.es,
    Locale.fr,
    Locale.nl,
  ],
  supporta: {
    url: 'https://supporta.cc',
    signUpUrl: {
      [Locale.de]: 'https://about.supporta.cc/en/submit-charity',
      [Locale.en]: 'https://about.supporta.cc/en/submit-charity',
      [Locale.es]: 'https://about.supporta.cc/en/submit-charity',
      [Locale.nl]: 'https://about.supporta.cc/nl/submit-charity',
      [Locale.fr]: 'https://about.supporta.cc/en/submit-charity',
    },
  },
  resale: {
    infoUrl: {
      [Locale.de]: 'https://atleta.cc/en/features/ticket-resale',
      [Locale.en]: 'https://atleta.cc/en/features/ticket-resale',
      [Locale.es]: 'https://atleta.cc/en/features/ticket-resale',
      [Locale.fr]: 'https://atleta.cc/en/features/ticket-resale',
      [Locale.nl]: 'https://atleta.cc/features/ticket-resale',
    },
  },
  termsUrl: {
    [Locale.de]: 'https://atleta.cc/en/terms-and-conditions',
    [Locale.en]: 'https://atleta.cc/en/terms-and-conditions',
    [Locale.es]: 'https://atleta.cc/es/terms-and-conditions',
    [Locale.fr]: 'https://atleta.cc/en/terms-and-conditions',
    [Locale.nl]: 'https://atleta.cc/terms-and-conditions',
  },
  fallbackLocale: Locale.en,
  locale: {
    de: {
      ...defaultLocale,
      dateFormats: {
        ...defaultLocale.dateFormats,

        input_date: 'dd.MM.yyyy',
        input_time: 'HH:mm',
        input_date_time: 'dd.MM.yyyy HH:mm',

        display_date: 'd. MMMM yyyy',
        display_time: 'HH:mm',
        display_date_time: 'd. MMMM yyyy, HH:mm',

        display_date_without_year: 'd. MMMM',
        display_date_time_without_year: 'd. MMMM, HH:mm',

        display_compact_date: 'dd.MM.yyyy',
        display_compact_date_time: 'dd.MM.yyyy, HH:mm',

        display_month: 'MMMM yyyy',
        display_day_of_week: 'EEEE',
        display_time_slot_date_time: 'EEEE d. MMMM, HH:mm',
        display_time_slot_date: 'EEEE d. MMMM',

        date_of_birth: 'd. MMMM yyyy',
      },
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
    en: defaultLocale,
    es: {
      ...defaultLocale,
      dateFormats: {
        ...defaultLocale.dateFormats,

        input_date: 'dd-MM-yyyy',
        input_time: 'HH:mm',
        input_date_time: 'dd-MM-yyyy HH:mm',

        display_date: 'd MMMM yyyy',
        display_time: 'HH:mm',
        display_date_time: 'd MMMM yyyy, HH:mm',

        display_date_without_year: 'd MMMM',
        display_date_time_without_year: 'd MMMM, HH:mm',

        display_compact_date: 'dd-MM-yyyy',
        display_compact_date_time: 'dd-MM-yyyy, HH:mm',

        display_month: 'MMMM yyyy',
        display_day_of_week: 'EEEE',
        display_time_slot_date_time: 'EEEE d MMMM, HH:mm',
        display_time_slot_date: 'EEEE d MMMM',

        date_of_birth: 'd MMMM yyyy',
      },
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
    fr: {
      ...defaultLocale,
      dateFormats: {
        ...defaultLocale.dateFormats,

        input_date: 'dd-MM-yyyy',
        input_time: 'HH:mm',
        input_date_time: 'dd-MM-yyyy HH:mm',

        display_date: 'd MMMM yyyy',
        display_time: 'HH:mm',
        display_date_time: 'd MMMM yyyy, HH:mm',

        display_date_without_year: 'd MMMM',
        display_date_time_without_year: 'd MMMM, HH:mm',

        display_compact_date: 'dd-MM-yyyy',
        display_compact_date_time: 'dd-MM-yyyy, HH:mm',

        display_month: 'MMMM yyyy',
        display_day_of_week: 'EEEE',
        display_time_slot_date_time: 'EEEE d MMMM, HH:mm',
        display_time_slot_date: 'EEEE d MMMM',

        date_of_birth: 'd MMMM yyyy',
      },
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
    nl: {
      ...defaultLocale,
      dateFormats: {
        ...defaultLocale.dateFormats,

        input_date: 'dd-MM-yyyy',
        input_time: 'HH:mm',
        input_date_time: 'dd-MM-yyyy HH:mm',

        display_date: 'd MMMM yyyy',
        display_time: 'HH:mm',
        display_date_time: 'd MMMM yyyy, HH:mm',

        display_date_without_year: 'd MMMM',
        display_date_time_without_year: 'd MMMM, HH:mm',

        display_compact_date: 'dd-MM-yyyy',
        display_compact_date_time: 'dd-MM-yyyy, HH:mm',

        display_month: 'MMMM yyyy',
        display_day_of_week: 'EEEE',
        display_time_slot_date_time: 'EEEE d MMMM, HH:mm',
        display_time_slot_date: 'EEEE d MMMM',

        date_of_birth: 'd MMMM yyyy',
      },
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
  },

  googleMapsKey: 'AIzaSyACECP3ffAnnK5rjJTKKp_A4bGa9zoT1gc',
  rollbarAccessToken: 'fb21e4e4d1784c6783f949a1dee11f9a', // post_client_item
  googleAnalyticsId: 'G-TNKZJD94X9',
  turnstileSiteKey: '0x4AAAAAABCkwx4dkUD3fGQZ',
  pinLength: 6,
};

export type Config = typeof config;
export type LocaleConfig = typeof config.locale.en;
export type DateTimeFormat = keyof typeof config.locale.en.dateFormats;

export default config;
