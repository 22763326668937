import { SVGProps } from 'react';

interface CircleProps {
  percentage?: number;
}

const Circle = ({ percentage }: CircleProps) => {
  const r = 8;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;

  return (
    <circle
      r={r}
      cx="50%"
      cy="50%"
      fill="transparent"
      stroke={percentage ? 'currentColor' : 'rgba(0, 0, 0, 0.15)'}
      strokeWidth={2.5}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
      strokeLinecap="round"
    />
  );
};

interface ProgressCircleProps extends SVGProps<SVGSVGElement> {
  percentage: number;
}

const ProgressCircle = ({ percentage, ...props }: ProgressCircleProps) => (
  <svg width={20} height={20} {...props}>
    <g transform={`rotate(-90 ${'10 10'})`}>
      <Circle />
      <Circle percentage={percentage} />
    </g>
  </svg>
);

export default ProgressCircle;
