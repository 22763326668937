
import useLocale from '../useLocale';

export interface DateRangeProps {
  start: string | null;
  end?: string | null;
}

/**
 * @param start UTC start date.
 * @param end UTC end date.
 */
const DateRange = ({ start, end }: DateRangeProps) => {
  const { formatDate, parseDate, locale } = useLocale();

  const startDate = new Date(formatDate(parseDate(start, { timezone: 'UTC' }), { format: 'internal_date_time' }));
  const endDate = new Date(formatDate(parseDate(end || start, { timezone: 'UTC' }), { format: 'internal_date_time' }));

  const format = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <>
      {/** @ts-ignore-next-line */}
      {format.formatRange(startDate, endDate)}
    </>
  );
};

export default DateRange;
