import styled, { css } from 'styled-components';

import { ArrayResponsiveProp, ResponsiveProp, SimpleProp } from '../../theme/helpers';
import { Span } from './Generic';
import SC from './SC';

interface ProfilePicProps {
  size?: ResponsiveProp<number> | SimpleProp<number> | ArrayResponsiveProp<number>;
  fit?: 'cover' | 'contain';
}

const ProfilePic = styled(Span)<SC<ProfilePicProps>>`
  ${({ sc: { fit = 'contain', size = 60, ...props } = {}, theme }) => css`
    background: ${theme.colors.gray[800]};
    border-radius: 100%;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    position: relative;

    ${fit === 'contain' && css`
      box-shadow: 0px -1px 2px rgba(0, 0, 0, .03), 0px 1.5px 2px rgba(0, 0, 0, .06), 0px 5px 5px rgba(0, 0, 0, .05);
    `}

    ${fit === 'cover' && css`
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, .05);
      }
    `}

    ${!!size && theme.responsiveProp(size, (picSize: number) => css`
      width: ${picSize || 60}px;
      min-width: ${picSize || 60}px;
      height: ${picSize || 60}px;

      ${fit === 'contain' && css`
        border: ${Math.round((picSize || 60) / 15)}px solid ${theme.colors.white};
      `}

      > span > span {
        font-size: ${Math.min(Math.max(8.5, Math.round((picSize || 60) / 2)), 18)}px;
        padding: ${Math.round((picSize || 60) / 10)}px;
      }
    `)}

    > img {
      width: 100%;
      height: 100%;
      object-fit: ${fit};
    }

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: hidden;

      > span {
        display: block;
        text-align: center;
        word-spacing: -.25em;
        line-height: 1;
        color: ${theme.getTitleColor(theme.getColor(props.background || 'gray.800'))} !important;
        font-weight: 500;
      }
    }
  `}
`;

export default ProfilePic;
