import { useTranslation } from 'react-i18next';

import { InvoiceDetailsInput } from '__generated__/graphql';
import Address, { containsAddress } from '../../backend/Common/Address';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import UI from '../UI';

interface InvoiceDetailsProps extends InvoiceDetailsInput {}

const InvoiceDetails = (invoice: InvoiceDetailsProps) => {
  const { t } = useTranslation('common');

  const hasAddress = containsAddress(invoice);
  const hasInvoiceDetails = invoice.company_name || invoice.reference || invoice.vat_id || hasAddress || invoice.phone;

  if (!hasInvoiceDetails) {
    return (
      <UI.Div sc={{ muted: true }}>
        {t('invoice_details.no_details_entered')}
      </UI.Div>
    );
  }

  return (
    <>
      {invoice.company_name && (
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500 }}>
            {t('invoice_details.company_name')}
          </UI.Legend>
          {invoice.company_name}
        </UI.Div>
      )}
      {invoice.reference && (
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500 }}>
            {t('invoice_details.reference')}
          </UI.Legend>
          {invoice.reference}
        </UI.Div>
      )}
      {invoice.vat_id && (
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500 }}>
            {t('invoice_details.vat_id')}
          </UI.Legend>
          {invoice.vat_id}
        </UI.Div>
      )}
      {hasAddress && (
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500 }}>
            {t('invoice_details.address')}
          </UI.Legend>
          <Address {...invoice} />
        </UI.Div>
      )}
      {invoice.phone && (
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500 }}>
            {t('invoice_details.phone')}
          </UI.Legend>
          <PhoneNumber phoneNumber={invoice.phone} />
        </UI.Div>
      )}
    </>
  );
};

export default InvoiceDetails;
